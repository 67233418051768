import { Flex } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { DataTable } from "src/molecules";

export interface AppsTableProps {
  apps: any[];
}

export const AppsTable = ({ apps }: AppsTableProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Flex width="100%">
      <DataTable
        columns={[
          { accessorKey: "id", header: "id " },
          { accessorKey: "name", header: "Name" },
          { accessorKey: "scheme", header: "Protocol" },
          { accessorKey: "domain", header: "Domain" },
          {
            id: "actions",
            cell: ({ row }) => {
              const to = row.original.to;

              return (
                <Button size="sm" onClick={() => navigate(to)}>
                  View
                </Button>
              );
            },
          },
        ]}
        data={apps}
      />
    </Flex>
  );
};
