export interface ParagraphProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Paragraph = ({
  children,
  onClick,
  className,
}: ParagraphProps): JSX.Element => {
  return (
    <p className={`text-base ${className}`} onClick={onClick}>
      {children}
    </p>
  );
};

export default Paragraph;
