import { Box, Container, Flex, ScrollArea } from "@radix-ui/themes";
import { Sidebar } from "../../organisms";
import { Outlet } from "react-router-dom";

export const AuthenticatedLayout = (): JSX.Element => {
  return (
    <Flex style={{ height: "100%" }}>
      <Box height="100%" style={{ width: "250px", position: "fixed" }}>
        <Sidebar />
      </Box>
      <Container p="6" style={{ marginLeft: "250px" }}>
        <ScrollArea style={{ height: "100%" }} scrollbars="both">
          <Outlet />
        </ScrollArea>
      </Container>
    </Flex>
  );
};

export default AuthenticatedLayout;
