import { useRouteError } from "react-router-dom";
import { Heading, Paragraph } from "../../atoms";

export const ErrorLayout = (): JSX.Element => {
  const error = useRouteError() as { statusText: string; message: string };

  return (
    <div className="flex flex-col justify-center items-center h-screen space-y-12">
      <Heading level={1}>Oops!</Heading>
      <Paragraph>Sorry, an unexpected error has occurred.</Paragraph>
      <Paragraph>
        <i>{error.statusText || error.message}</i>
      </Paragraph>
    </div>
  );
};

export default ErrorLayout;
