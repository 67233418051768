import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../contexts";
import { ImplementationGuideOrganism } from "../../../organisms";
import { Container, Flex, Heading, ScrollArea } from "@radix-ui/themes";
import { Button } from "../../../components/ui/button";

export const OnboardingImplementationGuidePage = (): JSX.Element => {
  const { appId, appName } = useOnboarding();
  const navigate = useNavigate();

  return (
    <Container p="1">
      <Flex direction="column" gap="6">
        <Heading size="8" weight="medium">
          Implementation Guide
        </Heading>

        <ScrollArea
          type="always"
          scrollbars="vertical"
          style={{ height: "calc(100vh - 120px)" }}
        >
          <Flex direction="column" pr="4" gap="6">
            <ImplementationGuideOrganism
              appId={appId || ""}
              appName={appName || ""}
            />

            <Flex>
              <Button type="button" onClick={() => navigate("/apps")}>
                View all of your apps
              </Button>
            </Flex>
          </Flex>
        </ScrollArea>
      </Flex>
    </Container>
  );
};
