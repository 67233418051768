import { useEffect, useState } from "react";
import { useAuthFetch } from "../../../hooks";
import { Link, useNavigate } from "react-router-dom";
import { Container, Flex, Heading } from "@radix-ui/themes";
import { Button } from "../../../components/ui/button";
import { AppsTable } from "../../../organisms";

export const AppsIndexPage = (): JSX.Element => {
  const [apps, setApps] = useState<any[] | undefined>(undefined);
  const [authFetch] = useAuthFetch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApps = async () => {
      const response = await authFetch(
        `${process.env.REACT_APP_GOPASSWORDLESS_URL}/apps`
      );
      const { data } = await response.json();
      setApps(
        data.map((app: any) => ({
          id: app.id,
          name: app.name,
          scheme: app.schema,
          domain: app.domain,
          to: `/apps/${app.id}`,
        }))
      );
    };
    fetchApps();
  }, [authFetch]);

  useEffect(() => {
    if (apps && apps.length === 0) {
      navigate("/onboarding");
    }
  }, [apps]);

  return (
    <Container p="1">
      <Flex gap="6" direction="column" width="100%">
        <Flex justify="between" align="center">
          <Heading size="8" weight="medium">
            Apps
          </Heading>
          <Link to="/apps/create">
            <Button>New app</Button>
          </Link>
        </Flex>

        <AppsTable apps={apps || []} />
      </Flex>
    </Container>
  );
};

export default AppsIndexPage;
