import { CheckCircledIcon } from "@radix-ui/react-icons";
import { Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const listItems = [
  "Login with Biometrics",
  "Phishing Resistant",
  "No credit card required",
];

export const UnauthenticatedLayout = (): JSX.Element => {
  const location = useLocation();

  return (
    <Grid columns={{ initial: "1", md: "2" }} height="100%">
      <Grid
        height="100%"
        style={{
          background: "#000000",
          borderRight: "1px solid rgb(221 221 221)",
          color: "white",
        }}
        align="center"
        justify="center"
        display={{ initial: "none", md: "grid" }}
      >
        {location.pathname.includes("login") && (
          <Flex
            direction="column"
            justify="center"
            align="center"
            style={{ textAlign: "left" }}
          >
            <Flex direction="column" gap="4">
              <Heading size={{ initial: "4", md: "8" }} weight="regular">
                Your app, no more passwords.
              </Heading>
              <ul>
                {listItems.map((item, index) => (
                  <ListItem key={index}>
                    <CheckCircledIcon />
                    {item}
                  </ListItem>
                ))}
              </ul>
            </Flex>
          </Flex>
        )}
        {location.pathname.includes("signup") && (
          <Flex
            style={{ height: "100%" }}
            align="start"
            justify="center"
            p="6"
            direction="column"
            gap="9"
          >
            <Flex direction="column" gap="2">
              <Heading size={{ initial: "4", md: "6" }} weight="medium">
                Throw away passwords forever and build modern, secure
                passwordless authentication flows with GoPasswordless
              </Heading>
            </Flex>
            <Flex direction="column" gap="2">
              <Heading size={{ initial: "3", md: "4" }} weight="medium">
                Start for free
              </Heading>
              <Text>
                No credit card required. Add passwordless login to your app in
                minutes with our React component.
              </Text>
            </Flex>
            <Flex direction="column" gap="2">
              <Heading size={{ initial: "3", md: "4" }} weight="medium">
                Secure by design
              </Heading>
              <Text>Built from the ground up using the WebAuthn standard.</Text>
            </Flex>
            <Flex direction="column" gap="2">
              <Heading size={{ initial: "3", md: "4" }} weight="medium">
                Your branding, your domain
              </Heading>
              <Text>
                You can add your own branding to our React component, and even
                use our lower level SDKs to build your own user interfaces.
              </Text>
            </Flex>
          </Flex>
        )}
      </Grid>

      <Grid
        height="100%"
        align={{ initial: "center", md: "center" }}
        justify="center"
      >
        <Flex justify="center" align="center" direction="column" gap="4">
          <Outlet />
        </Flex>
      </Grid>
    </Grid>
  );
};

export default UnauthenticatedLayout;
