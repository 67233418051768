import { ReactNode, createContext, useContext, useEffect } from "react";
import { decodeJwt } from "jose";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoPasswordlessContext } from "@gopasswordless/sdk";

export interface UserClaims {
  id: string;
  username: string;
}

export interface AuthContextType {
  token?: string;
  userClaims?: UserClaims;
  setUserClaims: (userClaims: UserClaims) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  setUserClaims: (userClaims: UserClaims) => {},
  logout: () => {},
});

export const AuthProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, logout: gpLogout } = useGoPasswordlessContext();

  useEffect(() => {
    try {
      // check the auth token is present and still valid, if not, redirect to
      // login page
      if (token) {
        // decode the token and check the expiry date
        const decodedToken = decodeJwt(token);
        const expiryDate = decodedToken.exp || 0;

        if (Date.now() / 1000 < expiryDate) {
          return;
        }
      }

      if (location.pathname === "/auth/signup") return;
      navigate("/auth/login");
    } catch (e) {
      console.error(e);
      navigate("/auth/login");
    }
  }, [navigate, location.pathname, token]);

  const setUserClaims = (userClaims: UserClaims) => {
    localStorage.setItem("user", JSON.stringify(userClaims));
  };

  const logout = () => {
    gpLogout();
    navigate("/auth/login");
  };

  const getToken = (): string | undefined => {
    return token;
  };

  const getUserClaims = (): UserClaims | undefined => {
    const user = localStorage.getItem("user");
    if (!user) return undefined;
    return JSON.parse(user) as UserClaims;
  };

  const authContextValue: AuthContextType = {
    token: getToken(),
    userClaims: getUserClaims(),
    setUserClaims,
    logout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};
