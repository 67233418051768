import { useCallback } from "react";
import { useAuth } from "../../contexts";
import { useLocation, useNavigate } from "react-router-dom";

export const useAuthFetch = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const authFetch = useCallback(
    async (url: string, options?: RequestInit) => {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 401) {
        localStorage.setItem("redirect", location.pathname);
        navigate("/auth/login");
      }

      return response;
    },
    [token, location.pathname, navigate]
  );

  return [authFetch];
};
