import { Container, Flex, Heading } from "@radix-ui/themes";
import { useAuthFetch } from "../../../hooks";
import AppForm from "../../../organisms/forms/app/app.form";
import { useNavigate } from "react-router-dom";

export const AppCreatePage = (): JSX.Element => {
  const [authFetch] = useAuthFetch();
  const navigate = useNavigate();

  const onSubmit = async (app: any) => {
    const response = await authFetch(
      `${process.env.REACT_APP_GOPASSWORDLESS_URL}/apps`,
      {
        method: "POST",
        body: JSON.stringify(app),
      }
    );

    if (response.status === 201) {
      const { data } = await response.json();
      navigate(`/apps/${data.id}`);
    }
  };

  return (
    <Container p="1">
      <Flex gap="4" direction="column" width="100%">
        <Heading size="8" weight="medium">
          Create an app
        </Heading>
        <AppForm onSubmit={onSubmit} />
      </Flex>
    </Container>
  );
};

export default AppCreatePage;
