import { Theme } from "@radix-ui/themes";
import { Outlet } from "react-router-dom";

export const AppLayout = (): JSX.Element => {
  return (
    <Theme style={{ height: "100%" }}>
      <Outlet />
    </Theme>
  );
};

export default AppLayout;
