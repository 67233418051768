import { createContext, useContext, useState } from "react";

type AppType = "REACT" | "WEB" | "NATIVE";
type AppScheme = "http" | "https";

export interface OnboardingContextType {
  appId?: string;
  appType?: AppType;
  appName?: string;
  appDomain?: string;
  appScheme?: AppScheme;
  appPort?: number;
  setAppId: (appId: string) => void;
  setAppType: (appType: AppType) => void;
  setAppName: (appName: string) => void;
  setAppDomain: (appDomain: string) => void;
  setAppScheme: (appScheme: AppScheme) => void;
  setAppPort: (appPort: number) => void;
}

export const OnboardingContext = createContext<OnboardingContextType>({
  appId: undefined,
  appType: "REACT",
  appName: undefined,
  appDomain: undefined,
  appScheme: "https",
  appPort: undefined,
  setAppId: () => {},
  setAppType: () => {},
  setAppName: () => {},
  setAppDomain: () => {},
  setAppScheme: () => {},
  setAppPort: () => {},
});

export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}): JSX.Element => {
  const [appId, setAppId] = useState<string>();
  const [appType, setAppType] = useState<AppType>();
  const [appName, setAppName] = useState<string>();
  const [appDomain, setAppDomain] = useState<string>();
  const [appScheme, setAppScheme] = useState<AppScheme>();
  const [appPort, setAppPort] = useState<number>();

  return (
    <OnboardingContext.Provider
      value={{
        appId,
        appType,
        appName,
        appDomain,
        appScheme,
        appPort,
        setAppId,
        setAppType,
        setAppName,
        setAppDomain,
        setAppScheme,
        setAppPort,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (): OnboardingContextType =>
  useContext(OnboardingContext);
