import { Flex } from "@radix-ui/themes";
import { DataTable } from "src/molecules";

export interface UsersTableProps {
  users: any[];
}

export const UsersTable = ({ users }: UsersTableProps): JSX.Element => {
  return (
    <Flex width="100%">
      <DataTable
        columns={[
          { accessorKey: "id", header: "id " },
          { accessorKey: "username", header: "Email" },
          { accessorKey: "createdAt", header: "Signed up" },
          { accessorKey: "verified", header: "Email Verified" },
        ]}
        data={users}
      />
    </Flex>
  );
};
