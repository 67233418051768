import { Flex } from "@radix-ui/themes";
import { useState } from "react";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { Button } from "../../../components/ui/button";

export interface AppFormProps {
  onSubmit: (app: any) => void;
}

const AppForm = ({ onSubmit }: AppFormProps): JSX.Element => {
  const [app, setApp] = useState({
    name: "",
    schema: "https", // default schema
    domain: "",
    port: "",
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit(app);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setApp((prevApp) => ({
      ...prevApp,
      [name]: value,
    }));
  };

  const handleSchemeChange = (value: string) => {
    setApp((prevApp) => ({
      ...prevApp,
      schema: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap="4">
        <div>
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            id="name"
            name="name"
            required
            onChange={handleChange}
            value={app.name}
          />
        </div>

        <div>
          <Label htmlFor="schema">Protocol</Label>

          <Select onValueChange={handleSchemeChange}>
            <SelectTrigger>
              <SelectValue placeholder="Select a protocol">
                {app.schema}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="http">http</SelectItem>
              <SelectItem value="https">https</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="domain">Domain</Label>
          <Input
            type="text"
            id="domain"
            name="domain"
            required
            onChange={handleChange}
            value={app.domain}
          />
        </div>

        <div>
          <Label htmlFor="port">Port (Optional)</Label>
          <Input
            type="text"
            id="port"
            name="port"
            onChange={handleChange}
            value={app.port}
          />
        </div>

        <Flex>
          <Button type="submit">Create App</Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default AppForm;
