import { useNavigate } from "react-router-dom";
import {
  GoPasswordlessWidgetComponent,
  useGoPasswordlessContext,
} from "@gopasswordless/sdk";
import { useEffect } from "react";

export const LoginPage = () => {
  const { token } = useGoPasswordlessContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/apps");
    }
  }, [token]);

  return <GoPasswordlessWidgetComponent />;
};

export default LoginPage;
