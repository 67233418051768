import { useState } from "react";
import { useAuthFetch } from "../auth-fetch/auth-fetch.hook";

export type UpdateAppReturnType = [
  (app: any) => Promise<any>,
  {
    data: any;
    isLoading: boolean;
    isError: boolean;
    error: string;
  }
];

export const useUpdateApp = ({ id }: { id: string }) => {
  const [authFetch] = useAuthFetch();
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const update = async (app: any) => {
    try {
      const response = await authFetch(
        `${process.env.REACT_APP_GOPASSWORDLESS_URL}/apps/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(app),
        }
      );

      if (response.status === 200) {
        setData(await response.json());
      } else {
        setIsError(true);
        setError("Something went wrong, please try again later.");
      }
    } catch (error) {
      setIsError(true);
      setError("Something went wrong, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return [update, { data, isLoading, isError, error }] as UpdateAppReturnType;
};
