import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../contexts";
import { useCreateApp } from "../../../hooks";
import { useEffect } from "react";
import { Container, Flex, Heading } from "@radix-ui/themes";
import { Input } from "../../../components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
} from "../../../components/ui/select";
import { Button } from "../../../components/ui/button";

export const OnboardingSetApplicationDetailsPage = (): JSX.Element => {
  const {
    setAppId,
    setAppDomain,
    setAppScheme,
    setAppPort,
    appId,
    appName,
    appDomain,
    appScheme,
    appPort,
  } = useOnboarding();

  const navigate = useNavigate();

  const [create, { data, isLoading, isError, error }] = useCreateApp();

  const handleSubmit = async () => {
    await create({
      name: appName,
      domain: appDomain,
      schema: appScheme || "https", // TODO: Fix typo in backend
      port: appPort,
    });
  };

  useEffect(() => {
    if (!data || !data.data) return;
    setAppId(data.data.id);
  }, [data, setAppId]);

  useEffect(() => {
    if (!appId) return;
    navigate("/onboarding/implementation-guide");
  }, [appId, navigate]);

  return (
    <Container p="1">
      <Flex direction="column" gap="6">
        <Heading size="8" weight="medium">
          App details
        </Heading>

        <Flex direction="column" gap="5">
          <Flex direction="column" gap="2">
            <Heading size="3" weight="light">
              Domain
            </Heading>
            <Input
              placeholder="myapp.com"
              value={appDomain}
              onChange={(e) => setAppDomain(e.target.value)}
            />
          </Flex>

          <Flex direction="column" gap="2">
            <Heading size="3" weight="light">
              Protocol
            </Heading>

            <Select onValueChange={setAppScheme}>
              <SelectTrigger>
                <SelectValue placeholder="protocol" />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value="https">https</SelectItem>
                <SelectItem value="http">http</SelectItem>
              </SelectContent>
            </Select>
          </Flex>

          <Flex direction="column" gap="2">
            <Heading size="3" weight="light">
              Port (Optional - defaults to 443 for https and 80 for http)
            </Heading>
            <Input
              placeholder=""
              value={appPort?.toString()}
              onChange={(e) => setAppPort(parseInt(e.target.value))}
            />
          </Flex>

          <div className="flex justify-between">
            <Button
              type="button"
              variant="outline"
              onClick={() => navigate("/onboarding/create-application")}
            >
              Back
            </Button>
            <Button type="button" onClick={handleSubmit} disabled={!appDomain}>
              Next
            </Button>
          </div>
        </Flex>
      </Flex>
    </Container>
  );
};
