import { Flex, Heading } from "@radix-ui/themes";
import { Code, Paragraph } from "../../atoms";

const reactCode = (
  appId: string,
  appName: string
) => `// Import the component from the SDK
import { GoPasswordlessContextProvider, GoPasswordlessWidgetComponent } from "@gopasswordless/sdk";

const GoPasswordlessDemo = () => {
  return (
    <GoPasswordlessContextProvider 
      settings={{ 
        appId: "${appId}",
        appName: "${appName}",
        appLogo: "YOUR_APP_LOGO",
        primaryColour: "YOUR_APP_PRIMARY_COLOUR",
        theme: "<'light' | 'dark' | 'glass'>"
      }}
    >
      <GoPasswordlessWidgetComponent />
    <GoPasswordlessContextProvider />
  );
};
`;

const validateCode = (
  appId: string
) => `import { validate, TokenClaims } from "@gopasswordless/sdk";

let tokenClaims: TokenClaims;

try {
  tokenClaims = await validate("${appId}", token);
} catch (error) {
  console.log("validate throws if the token signature is invalide");
}
`;

export interface ImplementationGuideProps {
  appId: string;
  appName: string;
}

export const ImplementationGuideOrganism = ({
  appId,
  appName,
}: ImplementationGuideProps): JSX.Element => {
  return (
    <Flex direction="column" gap="6">
      <Flex direction="column" gap="2">
        <Heading size={"6"} weight="medium">
          Install the SDK
        </Heading>
        <Code code="npm i @gopasswordless/sdk" language="bash" />
      </Flex>

      <Flex direction="column" gap="2">
        <Heading size={"6"} weight="medium">
          Usage
        </Heading>
        <Paragraph>
          The quickest way to get started is by using the React context and
          widget component. You can get started by wrapping your app with{" "}
          <strong>GoPasswordlessContextProvider</strong> and then adding the{" "}
          <strong>GoPasswordlessWidgetComponent</strong> to your app where you
          want to display the signup/login box.
        </Paragraph>
        <Code code={reactCode(appId || "", appName || "")} />
        <Paragraph>
          The authentication token for successful logins can be accessed using
          the <strong>useGoPasswordlessContext</strong> hook.
        </Paragraph>
        <Code code="const { token } = useGoPasswordlessContext();" />
      </Flex>

      <Flex direction="column" gap="2">
        <Heading size={"6"} weight="medium">
          Validating the token
        </Heading>
        <Paragraph>
          You can validate the token server side using the{" "}
          <strong>validate</strong> function.
        </Paragraph>
        <Code code={validateCode(appId || "")} />
      </Flex>
    </Flex>
  );
};
