import { useNavigate } from "react-router-dom";
import { Paragraph } from "../../../atoms";
import { Card, Container, Flex, Heading } from "@radix-ui/themes";
import { Button } from "../../../components/ui/button";

export const OnboardingEntryPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Container p="1">
      <Flex direction="column" gap="6">
        <Heading size="8" weight="medium">
          Getting Started
        </Heading>

        <Card style={{ padding: "2rem" }}>
          <Flex>
            <Flex direction="column" gap="6">
              <Heading size="6" weight="light">
                Integrate GoPasswordless into your application
              </Heading>

              <Paragraph>
                Integrate GoPasswordless into your application or use our React
                Component to get started in minutes.
              </Paragraph>

              <Flex>
                <Button
                  type="button"
                  onClick={() => navigate("/onboarding/create-application")}
                >
                  Create Application
                </Button>
              </Flex>
            </Flex>
            <Flex align="center" justify="center">
              <img
                className="w-1/2"
                src="https://gopasswordless.s3.eu-west-2.amazonaws.com/images/app/login-box-concept.png"
                alt="GoPasswordless login box"
              />
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
};
