import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import AppLayout from "./layouts/app/app.layout";
import {
  AuthenticatedLayout,
  ErrorLayout,
  OnboardingLayout,
  UnauthenticatedLayout,
} from "./layouts";
import { AppDetailPage, AppsIndexPage, LoginPage, SignupPage } from "./pages";
import { AuthProvider, OnboardingProvider } from "./contexts";
import AppCreatePage from "./pages/apps/create/app-create.page";
import {
  OnboardingCreateApplicationPage,
  OnboardingEntryPage,
  OnboardingImplementationGuidePage,
  OnboardingSetApplicationDetailsPage,
} from "./pages/onboarding";
import "@radix-ui/themes/styles.css";
import { GoPasswordlessContextProvider } from "@gopasswordless/sdk";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <GoPasswordlessContextProvider
        settings={{
          appId: process.env.REACT_APP_GOPASSWORDLESS_APP_ID || "",
          appName: "GoPasswordless",
          appLogo:
            "https://gopasswordless.s3.eu-west-2.amazonaws.com/images/logo.png",
          apiUrl:
            process.env.REACT_APP_GOPASSWORDLESS_URL ||
            "https://api.gopasswordless.dev/v1",
          theme: "light",
          primaryColour: "black",
        }}
      >
        <AuthProvider>
          <AppLayout />
        </AuthProvider>
      </GoPasswordlessContextProvider>
    ),
    errorElement: <ErrorLayout />,
    children: [
      {
        path: "/",
        element: <AuthenticatedLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/onboarding" replace />,
          },
          {
            path: "apps",
            element: <AppsIndexPage />,
          },
          {
            path: "apps/create",
            element: <AppCreatePage />,
          },
          {
            path: "apps/:id",
            element: <AppDetailPage />,
          },
          {
            path: "onboarding",
            element: (
              <OnboardingProvider>
                <OnboardingLayout />
              </OnboardingProvider>
            ),
            children: [
              {
                index: true,
                element: <OnboardingEntryPage />,
              },
              {
                path: "create-application",
                element: <OnboardingCreateApplicationPage />,
              },
              {
                path: "set-application-details",
                element: <OnboardingSetApplicationDetailsPage />,
              },
              {
                path: "implementation-guide",
                element: <OnboardingImplementationGuidePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/auth",
        element: <UnauthenticatedLayout />,
        children: [
          {
            path: "login",
            element: <LoginPage />,
          },
          {
            path: "signup",
            element: <SignupPage />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
