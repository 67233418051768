import { Flex } from "@radix-ui/themes";
import { useEffect, useState } from "react";

import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useUpdateApp } from "src/hooks";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  ExclamationTriangleIcon,
  CheckCircledIcon,
} from "@radix-ui/react-icons";

interface UpdateAppProps {
  id: string;
  name: string;
  scheme: string;
  domain: string;
}

export const UpdateApp = ({
  id,
  name,
  scheme,
  domain,
}: UpdateAppProps): JSX.Element => {
  const [update, { data, error, isLoading }] = useUpdateApp({ id });
  const [appName, setAppName] = useState<string>("");
  const [appScheme, setAppScheme] = useState<string>("");
  const [appDomain, setAppDomain] = useState<string>("");
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    setAppName(name);
    setAppScheme(scheme);
    setAppDomain(domain);
  }, [name, scheme, domain]);

  const handleSubmit = async () => {
    await update({
      name: appName,
      protocol: appScheme,
      domain: appDomain,
    });
  };

  return (
    <>
      <Flex gap="2" direction="column">
        <Label htmlFor="appId">App ID</Label>
        <Input type="text" id="appId" disabled value={id} />
      </Flex>
      <Flex gap="2" direction="column">
        <Label htmlFor="name">Name</Label>
        <Input
          type="text"
          id="name"
          value={appName}
          onChange={(e) => {
            setAppName(e.target.value);
            setHasChanges(true);
          }}
        />
      </Flex>
      <Flex gap="2" direction="column">
        <Label htmlFor="scheme">Scheme</Label>
        <Select
          value={appScheme}
          disabled
          onValueChange={(e) => {
            setAppScheme(e);
            setHasChanges(true);
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder={`${appScheme}`} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="http">http</SelectItem>
            <SelectItem value="https">https</SelectItem>
          </SelectContent>
        </Select>
      </Flex>
      <Flex gap="2" direction="column">
        <Label htmlFor="domain">Domain</Label>
        <Input
          type="text"
          id="domain"
          value={appDomain}
          disabled
          onChange={(e) => {
            setAppDomain(e.target.value);
            setHasChanges(true);
          }}
        />
      </Flex>
      {error && (
        <Alert variant="destructive">
          <ExclamationTriangleIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {data && (
        <Alert>
          <CheckCircledIcon />
          <AlertTitle>Success</AlertTitle>
          <AlertDescription>App updated successfully</AlertDescription>
        </Alert>
      )}
      <Flex>
        <Button disabled={!hasChanges || isLoading} onClick={handleSubmit}>
          Update
        </Button>
      </Flex>
    </>
  );
};
