import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthFetch } from "../../../hooks";
import { ImplementationGuideOrganism, UsersTable } from "../../../organisms";
import {
  Box,
  Container,
  Flex,
  Heading,
  ScrollArea,
  Text,
} from "@radix-ui/themes";
import { UpdateApp } from "../../../organisms/update-app/uptdate-app.organism";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";

interface User {
  id: string;
  username: string;
  createdAt: string;
  updatedAt: string;
  verified: string;
}

export const AppDetailPage = (): JSX.Element => {
  const [authFetch] = useAuthFetch();
  const { id } = useParams();
  const [app, setApp] = useState<any>();
  const [users, setUsers] = useState<User[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;

    const fetchApps = async () => {
      const response = await authFetch(
        `${process.env.REACT_APP_GOPASSWORDLESS_URL}/apps/${id}`
      );
      const { data } = await response.json();
      setApp(data);
    };

    fetchApps();
  }, [id, authFetch]);

  useEffect(() => {
    if (!id) return;

    const fetchUsers = async () => {
      const response = await authFetch(
        `${process.env.REACT_APP_GOPASSWORDLESS_URL}/apps/${id}/users`
      );
      const { data } = await response.json();
      setUsers(data);
    };

    fetchUsers();
  }, [id, authFetch]);

  const handleDelete = async () => {
    try {
      setDeleting(true);
      const response = await authFetch(
        `${process.env.REACT_APP_GOPASSWORDLESS_URL}/apps/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.status === 204) {
        navigate("/apps");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Container>
      <Flex gap="4" direction="column" width="100%">
        <Heading size="8" weight="medium">
          {app?.name}
        </Heading>

        <Flex gap="4" direction="row">
          <Card style={{ flex: 1 }}>
            <CardHeader>
              <Heading size="4" weight="light">
                Total Users
              </Heading>
            </CardHeader>
            <CardContent>
              <Text>{users?.length}</Text>
            </CardContent>
          </Card>

          <Card style={{ flex: 1 }}>
            <CardHeader>
              <Heading size="4" weight="light">
                Active Users (30d)
              </Heading>
            </CardHeader>
            <CardContent>
              <Text>{users?.length}</Text>
            </CardContent>
          </Card>
        </Flex>

        <Tabs defaultValue="quickstart">
          <TabsList>
            <TabsTrigger value="quickstart">Quick Start</TabsTrigger>
            <TabsTrigger value="users">Users</TabsTrigger>
            <TabsTrigger value="settings">Settings</TabsTrigger>
          </TabsList>

          <Box pt="3" pb="2">
            <TabsContent value="quickstart">
              <ScrollArea
                type="auto"
                scrollbars="vertical"
                style={{ height: "calc(100vh - 290px)" }}
              >
                <Flex pr="4" gap="4" direction="column">
                  <ImplementationGuideOrganism
                    appId={app?.id || ""}
                    appName={app?.name || ""}
                  />
                </Flex>
              </ScrollArea>
            </TabsContent>

            <TabsContent value="users">
              <UsersTable users={users} />
            </TabsContent>

            <TabsContent value="settings">
              <Flex direction="column" gap="4">
                <Flex gap="5" pt="4" direction="column">
                  <Heading size="6" weight="medium">
                    Basic Information
                  </Heading>
                  <UpdateApp
                    id={app?.id}
                    name={app?.name}
                    scheme={app?.schema}
                    domain={app?.domain}
                  />
                </Flex>

                <Flex gap="5" pt="4" direction="column">
                  <Heading size="6" weight="medium" color="red">
                    Danger Zone
                  </Heading>
                  <Flex
                    p="4"
                    direction="column"
                    gap="4"
                    style={{ border: "1px solid red", borderRadius: "8px" }}
                  >
                    <p>These actions are irreversible.</p>
                    <Flex>
                      <Dialog>
                        <DialogTrigger>
                          <Button variant="destructive">Delete App</Button>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogTitle>
                            Are you sure you want to delete this app?
                          </DialogTitle>
                          <DialogDescription>
                            This action is cannot be undone. The app and all of
                            its data will be permanently deleted. This includes
                            all of the user data associated with this app.
                          </DialogDescription>
                          <DialogFooter>
                            <Flex gap="4" justify="between" width="100%">
                              <DialogClose asChild>
                                <Button variant="secondary">Cancel</Button>
                              </DialogClose>
                              <Button
                                variant="destructive"
                                onClick={handleDelete}
                                disabled={deleting}
                              >
                                Delete App
                              </Button>
                            </Flex>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </TabsContent>
          </Box>
        </Tabs>
      </Flex>
    </Container>
  );
};

export default AppDetailPage;
