export interface HeadingProps {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
}

export const Heading = ({ level, children }: HeadingProps): JSX.Element => {
  switch (level) {
    case 1:
      return <h1 className="text-3xl font-normal">{children}</h1>;
    case 2:
      return <h2 className="text-2xl font-normal">{children}</h2>;
    case 3:
      return <h3 className="text-xl font-normal">{children}</h3>;
    case 4:
      return <h4 className="text-lg font-normal">{children}</h4>;
    case 5:
      return <h5 className="text-base font-normal">{children}</h5>;
    case 6:
      return <h6 className="text-sm font-normal">{children}</h6>;
    default:
      return <h1 className="text-3xl font-normal">{children}</h1>;
  }
};

export default Heading;
