import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";

export interface CodeProps {
  code: string;
  language?: string;
}

export const Code = ({ code, language }: CodeProps): JSX.Element => {
  return (
    <SyntaxHighlighter language={language || "javascript"} style={okaidia}>
      {code}
    </SyntaxHighlighter>
  );
};
