import {
  Button,
  Flex,
  Select,
  TableBody,
  TableCell,
  TableColumnHeaderCell,
  TableHeader,
  TableRoot,
  TableRow,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export interface TableProps {
  data: Array<any>;
  className?: string;
  initialPerPage?: number;
}

const ButtonLeft = styled(Button)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: transparent;
  color: black;
  border: 1px solid #d1d5db;

  &:hover {
    background: #edeef2;
  }
`;

const ButtonRight = styled(Button)`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: transparent;
  color: black;
  border: 1px solid #d1d5db;
  border-left: none;

  &:hover {
    background: #edeef2;
  }
`;

const ButtonNumber = styled(Button)`
  border-radius: 0;
  background: transparent;
  color: black;
  border-right: 1px solid #d1d5db;
  border-top: 1px solid #d1d5db;
  border-bottom: 1px solid #d1d5db;
  width: 32px;
  height: 32px;

  &:hover {
    background: #edeef2;
  }
`;

const Table = ({ data, className, initialPerPage = 10 }: TableProps) => {
  // Determine table headings based on the first data object's keys
  const headings = data.length > 0 ? Object.keys(data[0]) : [];

  // If any of the headings are "to", remove them
  const filteredHeadings = headings.filter((heading) => heading !== "to");

  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [totalPages, setTotalPages] = useState(data.length / perPage);
  const [currentData, setCurrentData] = useState(
    data.slice(page * perPage, page * perPage + perPage)
  );

  const navigate = useNavigate();

  useEffect(() => {
    setTotalPages(data.length / perPage);
    setCurrentData(data.slice(page * perPage, page * perPage + perPage));
  }, [page, perPage, data]);

  if (data.length === 0) {
    return (
      <div className="flex flex-col flex-1 space-y-6">
        <p>Nothing to show.</p>
      </div>
    );
  }

  return (
    <Flex direction="column" gap="4">
      <TableRoot>
        <TableHeader>
          <TableRow>
            {filteredHeadings.map((heading, index) => (
              <TableColumnHeaderCell key={index}>
                {heading}
              </TableColumnHeaderCell>
            ))}
          </TableRow>
        </TableHeader>

        <TableBody>
          {currentData.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              onClick={() => navigate(row.to)}
              style={{ cursor: "pointer" }}
            >
              {filteredHeadings.map((heading, colIndex) => (
                <TableCell key={colIndex}>
                  {typeof row[heading] === "boolean"
                    ? row[heading].toString()
                    : row[heading]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableRoot>

      <Flex align="center" justify="between">
        <Flex>
          <ButtonLeft disabled={page === 0} onClick={() => setPage(page - 1)}>
            {"<"}
          </ButtonLeft>
          {Array.from({ length: totalPages }, (_, i) => (
            <ButtonNumber key={i} onClick={() => setPage(i)} radius="none">
              {i + 1}
            </ButtonNumber>
          ))}
          <ButtonRight
            disabled={page === totalPages}
            onClick={() => setPage(page + 1)}
          >
            {">"}
          </ButtonRight>
        </Flex>

        <Select.Root
          defaultValue={perPage.toString()}
          onValueChange={(value) => setPerPage(Number(value))}
        >
          <Select.Trigger />
          <Select.Content>
            <Select.Group>
              <Select.Label>Per page</Select.Label>
              <Select.Item value={"10"}>10</Select.Item>
              <Select.Item value={"15"}>15</Select.Item>
              <Select.Item value={"20"}>20</Select.Item>
              <Select.Item value={"50"}>50</Select.Item>
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </Flex>
    </Flex>
  );
};

export default Table;
