import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export interface SidebarLinkProps {
  children: ReactNode | ReactNode[];
  to: string;
}

const ExternalLinkContainer = styled.div`
  background-color: "#f9f9f9";
  &:hover {
    background-color: #f9f9f9;
  }
  border-radius: 5px;
  padding: 0.2rem 1rem;
  width: 100%;
`;

const SidebarLinkContainer = styled(Link)<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? "#f9f9f9" : "transparent")};
  &:hover {
    background-color: #f9f9f9;
  }
  border-radius: 5px;
  padding: 0.2rem 1rem;
  width: 100%;
`;

export const SidebarLink = ({
  to,
  children,
}: SidebarLinkProps): JSX.Element => {
  const { pathname } = useLocation();
  const isSelected = pathname.includes(to);
  const isExternal = to.startsWith("http");

  if (isExternal) {
    return (
      <ExternalLinkContainer>
        <a href={to} target="_blank" rel="noreferrer">
          {children}
        </a>
      </ExternalLinkContainer>
    );
  }

  return (
    <SidebarLinkContainer to={to} selected={isSelected}>
      {children}
    </SidebarLinkContainer>
  );
};

export default SidebarLink;
