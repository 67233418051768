import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../contexts";
import { Container, Flex, Heading, Text } from "@radix-ui/themes";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import styled from "styled-components";

const SelectableCard = styled(Card)<{ selected: boolean }>`
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
  }
  background-color: ${(props) => (props.selected ? "#f7f7f7" : "none")};
`;

export const OnboardingCreateApplicationPage = (): JSX.Element => {
  const { setAppName, appName, setAppType, appType } = useOnboarding();
  const navigate = useNavigate();

  return (
    <Container p="1">
      <Flex direction="column" gap="6">
        <Heading size="8" weight="medium">
          Create an app
        </Heading>

        <Flex direction="column" gap="4">
          <Flex style={{ width: "40%" }} direction="column" gap="4">
            <Heading size="6" weight="light">
              What's the name of your app?
            </Heading>
            <Input
              placeholder="App name"
              onChange={(e) => setAppName(e.target.value)}
              value={appName}
            />
          </Flex>
        </Flex>

        <Flex direction="column" gap="4">
          <Heading size="6" weight="light">
            Choose an application type
          </Heading>

          <Flex gap="8" grow="1">
            <SelectableCard
              style={{ height: "auto", flex: "1" }}
              selected={appType === "REACT"}
              onClick={() => setAppType("REACT")}
            >
              <CardHeader>
                <Heading size="5" weight="light">
                  React App
                </Heading>
              </CardHeader>
              <CardContent>
                <Flex direction="column" gap="5">
                  <Flex justify="center">
                    <img
                      src="../../react_logo.png"
                      alt="React logo"
                      width="20%"
                    />
                  </Flex>
                  <Text>
                    A web application created using React or React based
                    framework like Next.js
                  </Text>
                </Flex>
              </CardContent>
            </SelectableCard>

            <SelectableCard
              style={{
                height: "auto",
                flex: "1",
              }}
              selected={appType === "WEB"}
              onClick={() => setAppType("WEB")}
            >
              <CardHeader>
                <Heading size="5" weight="light">
                  Web Application
                </Heading>
              </CardHeader>
              <CardContent>
                <Flex direction="column" gap="5">
                  <Flex justify="center">
                    <img
                      src="../../javascript.png"
                      alt="Javascript logo"
                      width="20%"
                    />
                  </Flex>
                  <Text>
                    A web application created using JavaScript or TypeScript.
                  </Text>
                </Flex>
              </CardContent>
            </SelectableCard>
            <Card style={{ flex: "1", height: "auto" }}>
              <CardHeader>
                <Heading size="5" weight="light">
                  Native (coming soon)
                </Heading>
              </CardHeader>

              <CardContent>
                <Flex direction="column" gap="3">
                  <Flex justify="center" gap="4">
                    <img src="../../ios.png" alt="iOS logo" width="25%" />
                    <img
                      src="../../android.jpeg"
                      alt="Android logo"
                      width="25%"
                    />
                  </Flex>
                  <Text>
                    A native app installed on your user's devices such as an
                    Android or iOS app.
                  </Text>
                </Flex>
              </CardContent>
            </Card>
          </Flex>
        </Flex>

        <Flex justify="end">
          <Button
            type="button"
            disabled={!appName}
            onClick={() => navigate("/onboarding/set-application-details")}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
};
